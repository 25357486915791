<template>
  <b-modal
    id="new-tiss-guide-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @hidden="clearModal"
  >
    <div class="modal-header">
      <div>
        <p class="modal-title">Nova guia</p>
      </div>
      <div>
        <v-close class="icon" @click="clearModal" />
      </div>
    </div>
    <div class="around-content">
      <b-row>
        <b-col cols="6">
          <b-form-group>
            <v-patient-input
              required
              v-model="form.patient"
              :error="false"
              @select="onSelectPatient"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <label for="health_plan">Convênio</label>
            <multiselect
              :loading="loading"
              id="health_plan"
              :showLabels="false"
              v-model="form.healthPlan"
              label="label"
              :options="healthPlans"
              @select="selectHealthPlan"
              placeholder="Selecionar"
              class="with-border"
            >
              <template slot="caret">
                <div class="chevron">
                  <v-chevron-down />
                </div>
              </template>
              <template slot="noOptions">
                <span v-if="!form.patient">Selecione um paciente</span>
                <span v-else>Nenhuma opção de convênio com TISS ativo</span>
              </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group>
            <label for="guide_type">Tipo de guia</label>
            <multiselect
              id="guide_type"
              v-model="form.guide_type"
              :options="guideOptions"
              label="label"
              placeholder="Selecionar"
              class="with-border"
              :show-labels="false"
            >
              <template slot="caret">
                <div class="chevron">
                  <v-chevron-down />
                </div>
              </template>
              <template slot="noOptions">
                <span v-if="form.healthPlan"> Nenhuma opção </span>
                <span v-else> Selecione um convênio </span>
              </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <div class="wrapper-button">
        <b-button variant="primary" :disabled="!canSave" @click="createGuide">
          Criar nova guia
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Close from '@/assets/icons/close.svg'
import ChevronDown from '@/assets/icons/chevron-down.svg'
import PatientInput from '@/components/General/PatientInput'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { handleGuideSelected } from '@/utils/invoicingTissHelper'

export default {
  name: 'NewTissGuideModal',
  props: {
    closeModal: Function,
    getClinicHealthPlanProp: Function,
    clinicHealthPlan: Object
  },
  components: {
    'v-close': Close,
    'v-chevron-down': ChevronDown,
    'v-patient-input': PatientInput
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      healthPlans: [],
      guideOptions: [],
      loading: false,
      form: {
        patient: null,
        healthPlan: null,
        guide_type: null
      }
    }
  },
  computed: {
    canSave() {
      return this.form.patient && this.form.healthPlan && this.form.guide_type
    }
  },
  methods: {
    clearModal() {
      this.form = {
        patient: null,
        healthPlan: null,
        guide_type: null
      }
      this.loading = false
      this.guideOptions = []
      this.healthPlans = []
      this.closeModal()
    },
    async getHealthPlans() {
      this.loading = true
      try {
        const response = await this.api.getPatientPlansTissActive(
          this.form.patient.id,
          this.clinic.id
        )
        this.healthPlans = response.data.map(plan => ({
          value: plan.health_plan.id,
          label: plan.health_plan.fantasy_name
        }))
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async getClinicHealthPlan(patientHealthPlanId) {
      await this.getClinicHealthPlanProp(patientHealthPlanId)
      this.setGuideTypes()
    },
    setGuideTypes() {
      this.guideOptions = []
      const guideOptions = [
        { label: 'Guia de consulta', value: 'consultation_guide' },
        { label: 'Guia SP/SADT', value: 'sp_sadt_guide' },
        // { label: 'Guia de solicitação de internação', value: 'hospitalization_request_guide' },
        // { label: 'Guia de resumo de internação', value: 'hospitalization_summary_guide' },
        { label: 'Guia de honorários', value: 'fee_guide' },
        // { label: 'Guia de outras despesas', value: 'other_expenses_guide' }
      ]
      const types = this.clinicHealthPlan.invoicing_tiss_setting.tiss_using_guide_type
      guideOptions.forEach(type => {
        if (types[type.value]) {
          this.guideOptions.push(type)
        }
      })
    },
    async onSelectPatient(patient) {
      this.healthPlans = []
      this.form.healthPlan = null

      this.guideOptions = []
      this.form.guide_type = null
      if (!patient) {
        return
      }
      await this.getHealthPlans()
    },
    async selectHealthPlan(healthPlan) {
      this.guideOptions = []
      this.form.healthPlan = healthPlan
      this.form.guide_type = null
      await this.getClinicHealthPlan(healthPlan.value)
    },
    async updateHealthPlan() {
      await this.getClinicHealthPlan(this.form.healthPlan.value)
    },
    handleGuide() {
      const modal = handleGuideSelected(this.form.guide_type.value)
      this.$bvModal.show(modal)
    },
    createGuide() {
      this.$emit('createGuide', this.form)
      this.handleGuide()
    }
  }
}
</script>

<style lang="scss">
#new-tiss-guide-modal {
  .modal-content {
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px !important;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          color: var(--type-active);
        }

        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }

      .around-content {
        padding: 24px 24px 0 24px;
      }
      .wrapper-button {
        width: 100%;
        display: inline-flex;
        margin: 20px 0 !important;
        justify-content: flex-end;

        button {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: var(--neutral-000);
        }
      }
    }
  }
}
</style>
